import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useDownloadExcel } from "react-export-table-to-excel";

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    // return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    return `${yyyy}-${mm}-${dd}`;
  } else {
    return "";
  }
};

const NFTTableWithLogs = () => {
  const [trackingData, setTrackingData] = useState([]);
  // const [isTracking, setIsTracking] = useState(false);
  const tableRef = useRef(null);
  const [sort, setSort] = useState('');

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "tracking_data",
    sheet: "Sales",
  });

  const SearchTerm = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const filted_data = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/nft/tracking/search",
        {
          params: {
            keyword: e.target.value,
          },
        }
      );
      setTrackingData(filted_data.data);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [enabledDateFilter, toggleEnabledDateFilter] = useState(false)

  //==================================================================================================

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const getDatabaseData = useCallback(async () => {
    try {
      let url = process.env.REACT_APP_BACKEND_URL + "/nft/tracking/db";
      const params = new URLSearchParams();
      if (sort.includes("purchase_date")) {
        params.set('order', sort.includes('top') ? 'asc' : 'desc');
        params.set('sort-by', 'purchase_date');
      } else if (sort.includes("issue_date")) {
        params.set('order', sort.includes('top') ? 'asc' : 'desc');
        params.set('sort-by', 'issue_date');
      }
      if (enabledDateFilter) {
        params.set('from', formatDate(startDate));
        params.set('to', formatDate(endDate));
      }
      url += `?${params.toString()}`;
      const response = await axios.get(url);
      console.log('rabi', response.data);
      setTrackingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [sort, enabledDateFilter, startDate, endDate]);

  useEffect(() => {
    getDatabaseData();
    let interval = setInterval(() => {
      getDatabaseData();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [getDatabaseData]);

  const formatLocaleDate = (date) => {
    return `${date.getFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
  }

  //==================================================================================================

  return (
    <div className="h-full p-6 overflow-x-auto">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold mb-4">NFT Sales Table</h1>
        <button
          className="bg-blue-500 text-white px-4 mb-2 rounded"
          onClick={onDownload}
        >
          Download xlsx
        </button>
        {/* <input
          type="text"
          placeholder="Search NFTs"
          onKeyDown={(e) => SearchTerm(e)}
        /> */}
        {/*<select value={period} onChange={(ev) => setPeriod(ev.currentTarget.value)}>
          <option value="0">All</option>
          <option value="1">A day</option>
          <option value="7">A week</option>
          <option value="30">A month</option>
        </select>*/}
        <div className="flex items-center gap-2 mr-5">
          <input type="checkbox" value={enabledDateFilter} onChange={(ev) => toggleEnabledDateFilter(ev.currentTarget.checked)} />
          From: 
          <input
            disabled={!enabledDateFilter}
            type="date"
            id="start"
            value={formatLocaleDate(startDate)}
            onChange={(ev) => {
              setStartDate(new Date(ev.currentTarget.value));
            }}
          />
          To: 
          <input
            disabled={!enabledDateFilter}
            type="date"
            id="start"
            name="trip-start"
            value={formatLocaleDate(endDate)}
            min={formatLocaleDate(startDate)}
            onChange={(ev) => setEndDate(new Date(ev.currentTarget.value))}
          />
          <p>Tracking sales...</p>
        </div>
      </div>

      <div className="border rounded-lg shadow">
        <table
          className="min-w-full border border-gray-300 bg-white text-sm "
          ref={tableRef}
        >
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                #
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Deployed NFTs
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Collection name
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Text prompt
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                NFT token ID
              </th>
              <th
                className="px-4 py-2 select-none border-b text-left font-medium text-gray-600 cursor-pointer"
                onClick={() => {
                  if (sort === 'issue_date.top') {
                    setSort('issue_date.bottom');
                  } else {
                    setSort('issue_date.top');
                  }
                }}
              >
                <div className="flex items-center gap-2">
                  Deployment date and time
                  {sort === 'issue_date.top' &&
                    <svg width={10} height={10} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                    </svg>
                  }
                  {sort === 'issue_date.bottom' &&
                    <svg width={10} height={10} className="rotate-180" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>                    </svg>
                  }
                  {sort !== 'issue_date.top' && sort !== 'issue_date.bottom' ?
                    <svg width="11" height="14" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.32979 8.55554H4.83055H0.779077C0.0857756 8.55554 -0.260875 9.38192 0.230213 9.86634L3.97115 13.5565C4.57057 14.1478 5.54552 14.1478 6.14494 13.5565L7.56765 12.1531L9.88587 9.86634C10.3697 9.38192 10.0231 8.55554 9.32979 8.55554Z" />
                      <path d="M0.779586 5.44434L5.27882 5.44434L9.3303 5.44434C10.0236 5.44434 10.3702 4.61796 9.87916 4.13354L6.13823 0.443354C5.53881 -0.147931 4.56385 -0.147931 3.96444 0.443354L2.54173 1.84676L0.2235 4.13354C-0.260365 4.61796 0.0862856 5.44434 0.779586 5.44434Z"/>
                    </svg>
                    :
                    null
                  }
                </div>
              </th>
              <th
                className="px-4 py-2 select-none border-b text-left font-medium text-gray-600 cursor-pointer"
                onClick={() => {
                  if (sort === 'purchase_date.top') {
                    setSort('purchase_date.bottom');
                  } else {
                    setSort('purchase_date.top');
                  }
                }}
              >
                <div className="flex items-center gap-2">
                  Purchase Date
                  {sort === 'purchase_date.top' &&
                    <svg width={10} height={10} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                    </svg>
                  }
                  {sort === 'purchase_date.bottom' &&
                    <svg width={10} height={10} className="rotate-180" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                      <path d="M3.22307 5.6087L0.339114 2.21739C-0.0121368 1.80435 -0.090521 1.33174 0.103961 0.799565C0.298443 0.267391 0.644887 0.000869565 1.14329 0H6.85574C7.35488 0 7.7017 0.266522 7.89618 0.799565C8.09066 1.33261 8.01191 1.80522 7.65992 2.21739L4.77596 5.6087C4.66504 5.73913 4.54488 5.83696 4.41547 5.90217C4.28606 5.96739 4.14741 6 3.99952 6C3.85162 6 3.71297 5.96739 3.58356 5.90217C3.45415 5.83696 3.33399 5.73913 3.22307 5.6087Z" fill="#000"/>
                    </svg>
                  }
                  {sort !== 'purchase_date.top' && sort !== 'purchase_date.bottom' ?
                    <svg width="11" height="14" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.32979 8.55554H4.83055H0.779077C0.0857756 8.55554 -0.260875 9.38192 0.230213 9.86634L3.97115 13.5565C4.57057 14.1478 5.54552 14.1478 6.14494 13.5565L7.56765 12.1531L9.88587 9.86634C10.3697 9.38192 10.0231 8.55554 9.32979 8.55554Z" />
                      <path d="M0.779586 5.44434L5.27882 5.44434L9.3303 5.44434C10.0236 5.44434 10.3702 4.61796 9.87916 4.13354L6.13823 0.443354C5.53881 -0.147931 4.56385 -0.147931 3.96444 0.443354L2.54173 1.84676L0.2235 4.13354C-0.260365 4.61796 0.0862856 5.44434 0.779586 5.44434Z" />
                    </svg>
                    :
                    null
                  }
                </div>
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Buyer address
              </th>
            </tr>
          </thead>
          <tbody>
            {trackingData.map((nft, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } hover:bg-gray-100`}
              >
                <td className="px-4 py-2 border-b">{index + 1}</td>
                <td className="px-4 py-2 border-b">{nft.nft_name}</td>
                <td className="px-4 py-2 border-b">{nft.collection}</td>
                <td className="px-4 py-2 border-b">
                  {nft.collection_generation}
                </td>
                <td className="px-4 py-2 border-b">{nft.nft_number}</td>
                <td className="px-4 py-2 border-b ">
                  {formatDate(nft.issue_date)}
                </td>
                <td className="px-4 py-2 border-b ">
                  {formatDate(nft.purchase_date)}
                </td>
                <td className="px-4 py-2 border-b ">{nft.buyer_address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NFTTableWithLogs;
