import axios from "axios";
import React, { useEffect, useState } from "react";
import useToast from "../hooks/useToast";

function PriceManagement() {
  const [nftTypes, setNftTypes] = useState([]);
  const [nftCount, setNftCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [selectedNftType, setSelectedNftType] = useState(null);
  const { showToast } = useToast();

  useEffect(() => {
    fetchNftTypes();
  }, []);

  const fetchNftTypes = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/nft-types"
      );
      setNftTypes(response.data);
    } catch (error) {
      console.error("Error fetching NFT types:", error);
    }
  };

  const handleCreateOrUpdate = async () => {
    try {
      if (selectedNftType) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/nft-types/${selectedNftType._id}`,
          {
            nftCount,
            price,
          }
        );
      } else {
        await axios.post(process.env.REACT_APP_BACKEND_URL + "/nft-types", {
          nftCount,
          price,
        });
      }
      fetchNftTypes();
      setNftCount(0);
      setPrice(0);
      setSelectedNftType(null);
    } catch (error) {
      console.error("Error creating/updating NFT type:", error);
    }
  };

  const handleDelete = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/nft-types/${id}`)
      .then((response) => {
        fetchNftTypes();
        showToast(response.data.message, "success");
      })
      .catch((error) => {
        showToast(error?.response?.data?.error, "error");
        console.error("Error deleting", error);
      });
  };

  const handleEdit = (nftType) => {
    setSelectedNftType(nftType);
    setNftCount(nftType.nftCount);
    setPrice(nftType.price);
  };

  return (
    <div className="App container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-center">NFT Management</h1>
      <div className="mb-6">
        <input
          type="number"
          value={nftCount}
          onChange={(e) => setNftCount(e.target.value)}
          placeholder="NFT Count"
          className="border p-2 mr-2 rounded w-1/4"
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Price"
          className="border p-2 mr-2 rounded w-1/4"
        />
        <button
          onClick={handleCreateOrUpdate}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {selectedNftType ? "Update NFT Type" : "Create NFT Type"}
        </button>
      </div>
      <div>
        <h2 className="text-2xl font-semibold mb-4">NFT Types</h2>
        {nftTypes.length === 0 ? (
          <p className="text-center text-gray-500">No NFT types</p>
        ) : (
          <ul className="space-y-4">
            {nftTypes.map((nftType) => (
              <li
                key={nftType._id}
                className="border p-4 rounded flex justify-between items-center"
              >
                <div>
                  <span className="font-medium">NFT Count:</span>{" "}
                  {nftType.nftCount},{" "}
                  <span className="font-medium px-4">Price:</span>{" "}
                  {nftType.price} POL
                </div>
                <div>
                  <button
                    onClick={() => handleEdit(nftType)}
                    className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(nftType._id)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default PriceManagement;
