export const PENDING = "Pending";
export const IMAGE_GENERATION = "Image Generation";
export const NFT_DEPLOY = "NFT Deploy";
export const NFT_MINT = "NFT Mint";
export const METADATA_SETTING = "Metadata Setting";
export const LIST_FOR_SALE = "List for Sale";
export const COMPLETED = "Completed";
export const TRACK_FOR_SALE = "Track for Sale";
export const PROGRESS_STATUS = [
  PENDING,
  IMAGE_GENERATION,
  NFT_DEPLOY,
  NFT_MINT,
  // METADATA_SETTING,
  LIST_FOR_SALE,
  TRACK_FOR_SALE,
  COMPLETED,
];
export const LARGE_COLLECTION = 5;
export const SMALL_COLLECTION = 2;
export const COLLECTION_TYPES = [SMALL_COLLECTION, LARGE_COLLECTION];